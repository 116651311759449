<template>
    <div>
      <div class="w-screen section-container laybare-services-banner relative space-top" v-if="hasNewSeasonalPage"></div>

      <div class="w-screen section-bg relative flex justify-end items-end xl:items-center space-top" v-else>
        <div class="absolute left-3  md:left-10  xl:left-24 2xl:left-32">
          <img class="w-80 md:w-106 xl:w-124 2xl:w-full" src="../../assets/images/services-page/generic-banners/lbplus-text.png" alt="generic-lbplus-description">
        </div>
        <img class="section-img -mr-14 md:-mr-20 xl:-mr-10 " src="../../assets/images/services-page/generic-banners/lbplus-model.png" alt="generic-lbplus-model">
      </div>
  
      <div class="w-screen service-we-offer py-8 mobileL:py-14 xl:py-16 2xl:py-20">
          <div class="content-wrapper max-w-3xl mobileL:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl mx-8 mobileL:mx-auto text-center text-white-1" id="service-we-offer">
              <h3 class="font-mermaid service-we-offer-content">Services we offer</h3>
              <p class="text-base mobileL:text-xl xl:text-2xl 2xl:text-3xl">Browse our full line of services ranging from facial and body
              waxing using our patented cold wax jelly - Through Care. This cold
              wax formulation naturally pulls the hair from the roots and leaves
              only smooth skin behind.</p>
          </div>
      </div>
  
      <div class="w-screen bg-brown-10 py-7 mobileL:py-10 px-5 mobileL:px-0 font-mermaid text-green-10" id="our-packages">

        <div class="w-full flex justify-center mobileL:w-2/12 mx-auto text-center">
          <div class="relative w-50">
            <h2 class="font-bold text-2xl mobileL:text-4xl xl:text-5xl 2xl:text-6xl tracking-tighter mobileL:tracking-widest text-center">WAXING</h2>
            <img class="w-5 mobileL:w-14 xl:w-20 absolute -right-3 -top-1.5 mobileL:-right-8 mobileL:-top-8" src="../../assets/images/home-page/flower.png" alt="Flower" />
          </div>
        </div>
  
        <div class="w-full mobileL:w-10/12 mx-auto mb-5">
          <div class="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 w-full gap-4 xl:gap-6">
            <ServiceCard v-for="(service, index) in waxingServices" :key="index" :service="service" />
          </div>
          <center class="mt-8" v-if="waxingPage !== lbpWaxingPageCount">
            <button class="underline text-xl xl:text-2xl 2xl:text-3xl" @click="loadMoreData('waxing')">See More</button>
          </center>
        </div>
  
        <div class="w-full flex justify-center mobileL:w-3/12 mx-auto text-center">
          <div class="relative w-50">
            <h2 class="font-bold text-2xl mobileL:text-4xl xl:text-5xl 2xl:text-6xl tracking-tighter mobileL:tracking-widest text-center">LIGHTENING</h2>
            <img class="w-5 mobileL:w-14 xl:w-16 2xl:w-20 absolute -right-3 -top-1.5 mobileL:-right-8 mobileL:-top-8" src="../../assets/images/home-page/flower.png" alt="Flower" />
          </div>
        </div>
  
        <div class="w-full mobileL:w-10/12 mx-auto mb-5">
          <div class="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 w-full gap-4 xl:gap-6">
            <ServiceCard v-for="(service, index) in lighteningServices" :key="index" :service="service" />
          </div>
          <center class="mt-8" v-if="lighteningPage !== lbpLighteningPageCount">
            <button class="underline text-xl xl:text-2xl 2xl:text-3xl" @click="loadMoreData('lightening')">See More</button>
          </center>
        </div>

        <div class="w-full flex justify-center mobileL:w-2/12 mx-auto text-center mt-12">
          <div class="relative w-50">
            <h2 class="font-bold text-2xl mobileL:text-4xl xl:text-5xl 2xl:text-6xl tracking-tighter mobileL:tracking-widest text-center">SCRUB</h2>
            <img class="w-5 mobileL:w-14 xl:w-16 2xl:w-20 absolute -right-3 -top-1.5 mobileL:-right-3 xl:right-0 2xl:right-1 mobileL:-top-7" src="../../assets/images/home-page/flower.png" alt="Flower" />
          </div>
        </div>

        <div class="w-full mobileL:w-10/12 mx-auto mb-5">
          <div class="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 w-full gap-4 xl:gap-6">
            <ServiceCard v-for="(servicePackage, index) in packages" :key="index" :service="servicePackage" />
          </div>
          <center class="mt-8" v-if="packagePage !== lbpScrubPageCount">
            <button class="underline text-xl xl:text-2xl 2xl:text-3xl" @click="loadMoreData()">See More</button>
          </center>
        </div>
      </div>
  
      
  
      <div class="w-screen bg-pink-14 py-7 mobileL:py-10 text-white-1" id="know-before-you-go">
        <h2 class="font-bold text-4xl mobileL:text-5xl xl:text-6xl 2xl:text-7xl tracking-wides font-mermaid text-center know-before-you-go-section">Know Before You Go</h2>
  
        <div class="w-11/12 mobileL:w-10/12 mx-auto mt-5 mobileL:mt-0">
          <div class="grid grid-cols-2 mobileL:grid-cols-4 justify-center text-center gap-5">
            <div class="w-full">
              <div class="rounded-full flex items-center justify-center w-9/12 mobileL:w-8/12 xl:w-9/12 2xl:w-10/12 mx-auto bg-green-10 p-6">
                  <img class="w-full" src="../../assets/images/services-page/lb-services-cream.png" alt="Cream">
              </div>
              <p class="font-semibold text-sm mobileL:text-base xl:text-xl 2xl:text-3xl mt-6">Exfoliate 1 week before your service</p>
            </div>
            <div class="w-full">
              <div class="rounded-full flex items-center justify-center w-9/12 mobileL:w-8/12 xl:w-9/12 2xl:w-10/12 mx-auto bg-green-10 p-6">
                <img class="w-full" src="../../assets/images/services-page/lb-services-stopwatch.png" alt="Cream">
              </div>
              <p class="font-semibold text-sm mobileL:text-base xl:text-xl 2xl:text-3xl mt-6">Wait at least 1 week after your period</p>
            </div>
            <div class="w-full">
              <div class="rounded-full flex items-center justify-center w-9/12 mobileL:w-8/12 xl:w-9/12 2xl:w-10/12 mx-auto bg-green-10 p-6">
                <img class="w-full" src="../../assets/images/services-page/lb-services-skirt.png" alt="Cream">
              </div>
              <p class="font-semibold text-sm mobileL:text-base xl:text-xl 2xl:text-3xl mt-6">Wear loose or breathable clothing</p>
            </div>
            <div class="w-full">
              <div class="rounded-full flex items-center justify-center w-9/12 mobileL:w-8/12 xl:w-9/12 2xl:w-10/12 mx-auto bg-green-10 p-6">
                <img class="w-full" src="../../assets/images/services-page/lb-services-coffee.png" alt="Cream">
              </div>
              <p class="font-semibold text-sm mobileL:text-base xl:text-xl 2xl:text-3xl mt-6">Skip the caffeine</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex';
  import ServiceCard from '../../components/ServiceCard.vue';
  export default {
    data() {
      return {
        lighteningPage: 1,
        waxingPage: 1,
        packagePage: 1,
        waxingServices: [],
        lighteningServices: [],
        packages: [],
        windowWidth: window.innerWidth,
        hasNewSeasonalPage:true,
        requestLimit: 8
      }
    },
  
    components: {
      ServiceCard
    },
  
    methods: {
      ...mapActions('services', [
        'fetchServices',
        'fetchPackages',
        'fetchBusinessUnit',
      ]),
  
      loadMoreData(serviceType) {
        if (serviceType === 'lightening') {
          this.lighteningPage += 1;
          this.fetchServices({ business_unit_id: this.$layBarePlusId, category_id: 7, page: this.lighteningPage, limit: this.requestLimit });
        } else if(serviceType === 'waxing') {
          this.waxingPage += 1;
          this.fetchServices({ business_unit_id: this.$layBarePlusId, category_id: 8, page: this.waxingPage, limit: this.requestLimit });
        } else {
          this.packagePage += 1;
          this.fetchPackages({business_unit_id: this.$layBarePlusId, page: this.packagePage, limit: this.requestLimit });
        }
      },

      handleResize() {
        this.windowWidth = window.innerWidth;
      },
    },
  
    computed: {
      ...mapGetters('services',
        ['allService',
        'allPackage',
        'allBusinessUnit',
        'allWaxingService',
        'allLighteningService',
        'lbpWaxingPageCount',
        'lbpLighteningPageCount',
        'lbpScrubPageCount'
        ]
      ),

      shouldShowElement() {
        return this.windowWidth > 480;
      },

      shouldNotShowElement(){
        return this.windowWidth < 480;
      },
 
    },

    mounted() {
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
  
    created() {
      this.fetchServices({business_unit_id: this.$layBarePlusId, category_id: 7, limit: this.requestLimit});
      this.fetchServices({business_unit_id: this.$layBarePlusId, category_id: 8, limit: this.requestLimit});
      this.fetchPackages({business_unit_id: this.$layBarePlusId, limit: this.requestLimit});
    },

    watch: {
    allWaxingService: {
      immediate: true,
      handler: function(services) {
        if (!services.length) return;

        this.waxingServices = services;
      }
    },

    allLighteningService: {
      immediate: true,
      handler: function(services) {
        if (!services.length) return;

        this.lighteningServices = services;
      }
    },

    allPackage: {
      immediate: true,
      handler: function (packages) {
        if (!packages.length) return;

        this.packages = packages;
      }
    }
  }
  }
  </script>
  
  <style scoped>
  .section-container {
    height: 600px;
  }

  .laybare-services-banner {
    background: url('../../assets/images/services-page/seasonal-banners/LBP_DEC_BANNER.jpg') no-repeat;
    background-size: 100% 100%;
  }

  .section-bg {
    background: url('../../assets/images/services-page/generic-banners/lbplus-background.jpg') no-repeat;
    background-size: cover;
  }

  .section-img{
    width:750px;
  }
  
  .space-top {
    margin-top: 90px;
  }

  .laybareplus-services-intro-text {
    text-shadow: 2px 4px 0px #469B88;
  }
  
  .service-we-offer {
      background: #47897B;
  }
  
  .service-we-offer-content {
      font-size: 4.25rem;
      text-shadow: 4px 4px #6CB93C;
  }
  
  .services-bg {
    background: url('../../assets/images/home-page/body-background.jpg') no-repeat;
    background-size: cover;
  }
  
  .know-before-you-go-section {
    text-shadow: 4px 4px #6e554281;
  }

  @media (max-width:480px) {

    .space-top {
      margin-top: 60px;
    }

    .section-container{
      height: 256px;
    }

    .laybare-services-banner {
      background: url('../../assets/images/services-page/seasonal-banners/LBP_DEC_BANNER.jpg') no-repeat;
      background-size: 100% 100%;
    }

    .section-img{
      width:420px;
    }
    .service-we-offer-content {
      font-size: 32px;
      text-shadow: 4px 4px #6CB93C;
    }
    
  }

  @media (min-width: 1280px) {
    .space-top {
      margin-top: 100px;
    }

    .section-container {
      height: calc(100vh - 100px);
    }
    

    .service-we-offer-content {
      font-size: 5.25rem;
      text-shadow: 5px 5px #6CB93C;
    }

    .know-before-you-go-section {
      text-shadow: 5px 5px #6e554281;
    }
  }


  @media (min-width: 1536px) {
    .space-top {
      margin-top: 128px;
    }

    .section-container {
      height: 110vh;
    }

    .service-we-offer-content {
      font-size: 6.25rem;
      text-shadow: 4px 4px #6CB93C;
    }

    .know-before-you-go-section {
      text-shadow: 6px 6px #6e554281;
    }
  }
  
  </style>